import {Component, Model, util} from 'derby';
import {Modal} from "../modal";

declare global {
    interface Window {
        Notifications?: Notifications;
    }
}

function isShowNative() {
    return !!('ontouchstart' in window || navigator.maxTouchPoints);
}

/**
 * Единичное срабатывание нажания клавиши
 * @param key
 * @param cb
 */
function onceKeydown(key: string, cb: (event?: KeyboardEvent) => void) {
    const once = function (e: KeyboardEvent) {
        if (e.key === key) {
            document.body.removeEventListener('keydown', once);

            return cb(e);
        }
    };

    document.body.addEventListener('keydown', once);

    return function () {
        document.body.removeEventListener('keydown', once);
    };
}

/**
 * Всплывающие уведомления
 */
export class Notifications extends Component {
    static readonly is = 'notifications';
    static readonly view = __dirname;
    static readonly style = __dirname;

    protected $popupList: Model;
    protected modal_confirm: Modal;
    protected modal_prompt: Modal;
    protected modal_prompt_input: HTMLInputElement;

    init() {
        this.model.setNull('popup_list', {});
        this.$popupList = this.model.at('popup_list');

        if (!util.isServer) {
            window.Notifications = this;
        }
    }

    create() {
        this.model.start('popup_list_ids', 'popup_list', popup_list => {
            return Object.values<any>(popup_list || {}).map(item => item.id);
        });
        this.model.refList('popup_list_arr', 'popup_list', 'popup_list_ids')
    }

    /**
     * Успешное сообщение
     * @param message
     * @param timeout
     */
    async success(message: string, timeout: number = 7000): Promise<void> {
        return this.popupCreate('success', message, timeout);
    }

    /**
     * Успешное сообщение
     * @param message
     * @param timeout
     */
    async error(message: string, timeout: number = 30000): Promise<void> {
        return this.popupCreate('error', message, timeout);
    }

    /**
     * Создание всплывающего сообщения
     * @param type
     * @param message
     * @param timeout
     */
    async popupCreate(type: 'success' | 'error', message: string, timeout: number = 5000): Promise<void> {
        const popupId = await this.$popupList.addAsync(null, {
            message: message,
            type: type,
            animation: 'show',
            timeout: timeout,
        });

        if (timeout > 0) {
            setTimeout(() => this.popupHide(popupId), timeout);
        }
    }

    /**
     * Скрываем всплывающее сообщение
     * @param popupId
     */
    async popupHide(popupId: string) {
        const $popup = this.$popupList.at(popupId);

        if ($popup.get()) {
            $popup.set('animation', 'hide');

            setTimeout(() => $popup.del(), 210);
        }
    }

    /**
     * Замена обычному confirm
     */
    async confirm(message: string, title = 'Подтверждение', okText = 'Ok', cancelText = 'Отмена'): Promise<void> {
        if (isShowNative()) {
            return new Promise((resolve, reject) => {
                if (window.confirm(message)) {
                    resolve();
                } else {
                    reject();
                }
            });
        }

        await this.model.setAsync('modal_confirm', {
            title: title,
            content: message,
            btn_ok: okText,
            btn_cancel: cancelText,
        });

        return new Promise((resolve, reject) => {
            const removeOnceEnter = onceKeydown('Enter', () => {
                this.modal_confirm.hide('ok');
            });

            const removeOnceEscape = onceKeydown('Escape', () => {
                this.modal_confirm.hide('escape');
            });

            this.modal_confirm.once('hide', action => {
                removeOnceEnter();
                removeOnceEscape();

                if (action === 'ok') {
                    resolve();
                } else {
                    reject();
                }
            });

            this.modal_confirm.show();
        });
    }

    /**
     * Замена обычному prompt
     */
    async prompt(title = 'Введите значение', defValue = '', okText = 'Ok', cancelText = 'Отмена'): Promise<string | null> {
        if (isShowNative()) {
            return new Promise((resolve) => {
                return resolve(window.prompt(title, defValue));
            });
        }

        await this.model.setAsync('modal_prompt', {
            title: title,
            value: defValue,
            btn_ok: okText,
            btn_cancel: cancelText,
        });

        return new Promise((resolve) => {
            const removeOnceEnter = onceKeydown('Enter', () => {
                this.modal_prompt.hide('ok');
            });

            const removeOnceEscape = onceKeydown('Escape', () => {
                this.modal_prompt.hide('escape');
            });

            this.modal_prompt.once('hide', action => {
                removeOnceEnter();
                removeOnceEscape();

                if (action === 'ok') {
                    resolve(this.model.get('modal_prompt.value'));
                } else {
                    resolve(null);
                }
            });

            this.modal_prompt.once('show', () => {
                let int = setInterval(() => {
                    if (this.modal_prompt_input) {
                        this.modal_prompt_input.focus();
                        if (defValue) {
                            this.modal_prompt_input.select();
                        }
                        clearInterval(int);
                    }
                }, 13);
            });

            this.modal_prompt.show();
        });
    }
}