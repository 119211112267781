import {App, Model} from "derby";

export function modelExtend(app: App, options?: any) {

    /**
     * Реконнект с каллбеком
     * @param cb
     */
    app.derby.Model.prototype.onceReconnect = function (cb: () => void): void {
        let listener = this.on('change', '$connection.state', () => {
            if (this.get('$connection.state') === 'connected') {
                this.removeListener('change', listener);
                cb();
            }
        });

        this.reconnect();
    };

    /**
     * Авто-подписка на связанные элементы
     */
    app.derby.Model.prototype.subscribeToRelations = function (collection: string, relKey: string, relCollection: string): void {
        let oldListIds = [];
        let model: Model = this;

        let idPath = this.id();
        let idListPath = '_me.' + idPath + '.id_list';
        let eventPath = '_me.' + idPath + '.event';

        this.start(idListPath, collection, (items) => {
            let listIds = {};

            if (Array.isArray(items)) {
                items.forEach(item => {
                    if (item[relKey]) {
                        listIds[item[relKey]] = true;
                    }
                });
            } else {

                for (let k in items) {
                    if (items.hasOwnProperty(k)) {
                        if (items[k] && items[k][relKey]) {
                            listIds[items[k][relKey]] = true;
                        }
                    }
                }
            }

            listIds = Object.keys(listIds)
                .filter(id => !!id)
                .map(id => relCollection + '.' + id)
                .sort((a, b) => a > b ? 1 : -1);

            return listIds;
        });

        this.start(eventPath, idListPath, (listIds) => {
            let newAddedIds = listIds.filter(item => oldListIds.indexOf(item) === -1);
            let removedIds = oldListIds.filter(item => listIds.indexOf(item) === -1);

            model.subscribe(newAddedIds);
            model.unsubscribe(removedIds);

            oldListIds = listIds.slice();

            return listIds;
        });
    };
}