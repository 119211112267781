import {Component} from "derby";

/**
 * Уведомление о потерянном подключении в браузере
 *
 * Можно передать beforeunload = true для включения предупреждения об ожидании операций
 */
export class ConnectionIndicator extends Component {
    static is = 'connection-indicator';
    static view = __dirname;
    static style = __dirname;

    create(model, dom) {
        this.model.set('initialized', true); // Предотвращаем серверный рендеринг компонента

        // Не даем сбросить страницу, если есть какие-то операции записи
        // Событие назначается через небольшой таймаут, чтобы не блокировать переход по страницам на клиентских страницах
        let unloadTimeout = setTimeout(() => {
            dom.on('beforeunload', window, function (e) {
                if (!model.get('beforeunload') || !model.hasWritePending()) return;

                e.returnValue = 'Покинуть страницу?';
                return e.returnValue;
            });
        }, 15000);

        this.on('destroy', () => {
            unloadTimeout && clearTimeout(unloadTimeout);
        });

        // Отслеживаем состояние
        function whenPending(cb) {
            let interval = setInterval(() => {
                if (model.hasPending()) {
                    !!interval && clearInterval(interval);

                    cb();
                }
            }, 333);
        }

        function onPending() {
            model.set('pending', true);

            model.whenNothingPending(() => {
                model.set('pending', false);

                whenPending(onPending);
            });
        }

        whenPending(onPending);
    }

    close() {
        this.model.set('hideIndicator', true);

        setTimeout(() => {
            this.model.set('hideIndicator', false);
        }, 30000);
    }

    reconnect() {
        this.model.set('hideReconnect', true);

        setTimeout(() => {
            this.model.set('hideReconnect', false);
        }, 3000);

        this.model.reconnect();
    }

    reload() {
        window.location.reload();
    }
}