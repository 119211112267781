import {UploaderCompleteCb, UploaderObject} from "./uploader-object";
import {BillingType} from "./models/ProjectModel";

let hasOwn = {}.hasOwnProperty;

let monthNameList = [
    'янв', 'фев',
    'март', 'апр', 'май',
    'июн', 'июл', 'авг',
    'сен', 'окт', 'ноя',
    'дек'];

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

export type FileUploadType = 'none' | 'files' | 'avatar';

export function leadZero(num) {
    if (num < 10) {
        return '0' + num;
    }

    return '' + num;
}

export function monthName(month: number): string {
    return monthNameList[month] ? monthNameList[month] : '' + month;
}

export function isToday(someDate: Date): boolean {
    const today = new Date();
    return someDate.getUTCDate() === today.getUTCDate() &&
        someDate.getUTCMonth() === today.getUTCMonth() &&
        someDate.getUTCFullYear() === today.getUTCFullYear();
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// https://github.com/JedWatson/classnames/blob/master/index.js
export function classNames(...args: Array<string | number | string[] | number[] | { [key: string]: boolean }>): string {
    let classes = [];

    for (let i = 0; i < arguments.length; i++) {
        let arg = arguments[i];
        if (!arg) continue;

        let argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        } else if (Array.isArray(arg) && arg.length) {
            let inner = classNames.apply(null, arg);
            if (inner) {
                classes.push(inner);
            }
        } else if (argType === 'object') {
            for (let key in arg) {
                if (hasOwn.call(arg, key) && arg[key]) {
                    classes.push(key);
                }
            }
        }
    }

    return classes.join(' ');
}

export function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

export function date_pretty(date: Date | number | string, tzOffset: number = null, todayMode = true): string {
    date = new Date(date);

    if (!(date instanceof Date) || (date.getTime && isNaN(date.getTime()))) {
        return '-';
    }

    if (typeof tzOffset !== 'number') {
        tzOffset = new Date().getTimezoneOffset();
    }

    date = convertDateToUTC(date);
    date.setTime(date.getTime() - tzOffset * 60 * 1000);

    const now = convertDateToUTC(new Date());
    now.setTime(now.getTime() - tzOffset * 60 * 1000);

    const time = leadZero(date.getHours()) + ':' + leadZero(date.getMinutes());

    if (todayMode && isToday(date)) {
        return time;
    }

    const day = leadZero(date.getDate()) + ' ' + monthName(date.getMonth())
    const year = date.getFullYear();

    if (year === now.getFullYear()) {
        return day + ' ' + time;
    }

    return day + ' ' + year + ' ' + time;
}

/**
 * Запрос загрузки файла
 */
export function uploadRequest(done: (err?: Error | null, files?: FileList) => void) {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;

    input.onchange = (e: HTMLInputEvent) => {
        if (e && e.target && e.target.files) {
            return done(null, e.target.files);
        }
    };

    input.click();
}

/**
 * Делаем элемент с загрузкой файлов
 * @param domElement
 * @param onDragEnter
 * @param onDragLeave
 * @param onStart
 * @param onDone
 * @param type
 */
export function uploadDropzone(domElement: HTMLElement, onDragEnter: () => void, onDragLeave: () => void, onStart: (file: File) => void, onDone: UploaderCompleteCb, type: FileUploadType = 'none') {
    let lastenter;

    domElement.addEventListener('dragenter', (e) => {
        e.preventDefault();
        lastenter = e.target;
        onDragEnter();
    }, false);

    domElement.addEventListener('dragleave', (e) => {
        e.preventDefault();

        if (e.target === lastenter) {
            onDragLeave();
        }
    }, false);

    domElement.addEventListener('drop', (e) => {
        e.preventDefault();
        onDragLeave();

        let dt = e.dataTransfer;
        if (dt) {
            let files = dt.files;

            if (files && files.length) {
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    onStart(file);

                    sendFile(file, onDone, type);
                }
            }
        }
    }, false);
}

/**
 * Загрузка файла с запросом загрузки
 */
export function uploadFile(onStart: (file: File) => void, onDone: UploaderCompleteCb, type: FileUploadType = 'none') {
    uploadRequest((err, files) => {
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                onStart(file);

                sendFile(file, onDone, type);
            }
        }
    });
}

/**
 * Отправка файла на сервер
 */
export function sendFile(file: File, onDone: UploaderCompleteCb, type: FileUploadType = 'none'): void {
    new UploaderObject({
        file: file,
        url: '/files/upload?type=' + type,
        fieldName: 'file',
        oncomplete: (uploadSuccess, response) => {
            return onDone(uploadSuccess, response);
        },
    });
}

export function parseIntEx(value: any, defaultValue: number, maxValue: number): number {
    value = parseInt(value, 10) || defaultValue;
    return Math.min(value, maxValue);
}

/**
 * Async timeout
 * @param delay
 */
export async function timeout(delay: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(() => resolve(), delay);
    });
}

/**
 * Фильтрация активных биллингов
 */
export function activeBillings(billing: BillingType[], until: number = null) {
    if (until === null || until === undefined) {
        until = Date.now();
    }

    if (!billing || billing.length === 0) {
        return [];
    }

    return billing.filter(item => item.date_until > until && item.clients_used < item.clients_limit);
}

/**
 * Добавляем указанное число минут ко времени
 */
export function timeAddMinutes(time: string, addMinutes: number): string {
    const [hours, minutes] = time.split(':');
    const date = new Date();

    date.setUTCHours(parseInt(hours, 10));
    date.setUTCMinutes(parseInt(minutes, 10));
    date.setTime(date.getTime() + addMinutes * 60 * 1000);

    return leadZero(date.getUTCHours()) + ':' + leadZero(date.getUTCMinutes());
}

/**
 * Проверка, что время находится между двумя другими
 */
export function timeBetween(time: string, start: string, end: string): boolean {
    if (start === end) {
        return start === time;
    }

    function makeDate(tstr: string): Date {
        const [hours, minutes] = tstr.split(':');
        const date = new Date();

        date.setUTCHours(parseInt(hours, 10));
        date.setUTCMinutes(parseInt(minutes, 10));

        return date;
    }

    const timeDate = makeDate(time);
    const startDate = makeDate(start);
    const endDate = makeDate(end);

    if (endDate.getTime() < startDate.getTime()) {
        startDate.setTime(startDate.getTime() - 1000 * 60 * 60 * 12);
        endDate.setTime(endDate.getTime() + 1000 * 60 * 60 * 12);
        timeDate.setTime(timeDate.getTime() + 1000 * 60 * 60 * 12);
    }

    return timeDate.getTime() >= startDate.getTime() && timeDate.getTime() <= endDate.getTime();
}