/*
 * Загрузчик файла на сервер.
 * file       - объект File (обязателен)
 * url        - строка, указывает куда загружать (обязателен)
 * fieldName  - имя поля, содержащего файл (как если задать атрибут name тегу input)
 * onprogress - функция обратного вызова, вызывается при обновлении данных
 * oncomplete - функция обратного вызова, вызывается при завершении загрузки, принимает два параметра:
 *      uploaded - содержит true, в случае успеха и false, если возникли какие-либо ошибки;
 *      data - в случае успеха в него передается ответ сервера
 *
 *      если в процессе загрузки возникли ошибки, то в свойство lastError объекта помещается объект ошибки, содержащий два поля: code и text
 */

interface XMLHttpRequestBinary extends XMLHttpRequest {
    sendAsBinary?: (binaryString: string) => void;
}

interface UploaderParams {
    file: File;
    url: string;
    fieldName: string;
    onprogress?: (loaded: number, total: number) => void;
    oncomplete?: UploaderCompleteCb;
}

export type UploaderCompleteCb = (uploadSuccess: boolean, response?: any, uploadCanceled?: boolean) => void;

export class UploaderObject {
    protected xhr: XMLHttpRequestBinary = new XMLHttpRequest();
    protected reader = new FileReader();
    protected progress = 0;
    protected successful = false;
    protected uploaded = false;
    protected uploadCanceled = false;
    protected lastError?: {
        code: number;
        text: string;
    };

    constructor(params: UploaderParams) {
        let self = this;

        self.reader.onload = function () {
            self.xhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                    if (params.onprogress instanceof Function) {
                        params.onprogress.call(self, e.loaded, e.total);
                    }
                }
            }, false);

            self.xhr.upload.addEventListener("load", function () {
                self.progress = 100;
                self.uploaded = true;
            }, false);

            self.xhr.upload.addEventListener("error", function () {
                self.lastError = {
                    code: 1,
                    text: 'Error uploading on server',
                };
            }, false);

            self.xhr.onreadystatechange = function () {
                let callbackDefined = params.oncomplete instanceof Function;

                if (this.readyState === 4) {
                    if (this.status === 200) {
                        if (!self.uploaded) {
                            if (callbackDefined) {
                                params.oncomplete.call(self, false);
                            }
                        } else {
                            self.successful = true;

                            if (callbackDefined) {
                                let response = this.responseText;
                                try {
                                    response = JSON.parse(response);
                                } catch (e) {
                                }

                                params.oncomplete.call(self, true, response, self.uploadCanceled);
                            }
                        }
                    } else {
                        self.lastError = {
                            code: this.status,
                            text: 'HTTP response code is not OK (' + this.status + ')',
                        };

                        if (callbackDefined) {
                            params.oncomplete.call(self, false, null, self.uploadCanceled);
                        }
                    }
                }
            };

            let boundary = "xxxxxxxxx";

            let body = "--" + boundary + "\r\n";
            body += "Content-Disposition: form-data; name='" + (params.fieldName || 'file') + "'; filename='" + unescape(encodeURIComponent(params.file.name)) + "'\r\n";
            body += "Accept: application/json\r\n";
            body += "Content-Type: application/octet-stream\r\n\r\n";
            body += self.reader.result + "\r\n";
            body += "--" + boundary + "--";

            self.xhr.open("POST", params.url);
            self.xhr.setRequestHeader("Cache-Control", "no-cache");
            self.xhr.setRequestHeader("Accept", "application/json");

            if (self.xhr.sendAsBinary) {
                self.xhr.setRequestHeader("Content-Type", "multipart/form-data; boundary=" + boundary);
                self.xhr.sendAsBinary(body);
            } else {
                let formData = new FormData();
                formData.append(params.fieldName || 'file', params.file);
                self.xhr.send(formData);
            }
        };

        self.reader.readAsBinaryString(params.file);
    }

    cancelUpload(): void {
        this.uploadCanceled = true;
        this.xhr.abort();
    }
}