import {Component} from "derby";

export class Modal extends Component {
    static is = 'modal';
    static view = __dirname;
    static style = __dirname;

    create(model, dom) {
        let that = this;
        dom.on('keydown', function (e) {
            if (!model.get('show')) return;

            if (e.key === 27) {  // Escape
                that.hide('escape');
            }
        });
    }

    show() {
        let model = this.model;
        this.emitDelayable('show', function () {
            document.body.classList.add('scroll-locked');
            model.set('fadeOut', false);
            model.set('show', true);
        });
    }

    hide(action = 'normal') {
        let cancelled = this.emitCancellable('hide', action);
        if (cancelled) return;
        let model = this.model;
        model.set('fadeOut', true);
        setTimeout(function () {
            document.body.classList.remove('scroll-locked');
            model.set('show', false);
        }, 250);
    }
}