import {Component} from 'derby';

let checkThrottled = false;
const intervalTime = 1000 * 60 * 5;

/**
 * Проверка обновлений
 */
export class DerbyUpdateCheckComponent extends Component {
    static readonly is = 'derby-update-check';
    static readonly view = __dirname;
    static readonly style = __dirname;

    init() {
        this.model.ref('$connection', this.model.scope('$connection'));
        this.model.ref('_custom', this.model.scope('_custom'));
    }

    create() {
        const interval = setInterval(() => {
            this.check();
        }, intervalTime);

        this.model.on('change', '$connection.state', () => {
            if (this.model.get('$connection.state') === 'connected') {
                this.check(true);
            }
        });

        this.check();

        this.on('destroy', () => {
            !!interval && clearInterval(interval);
        });
    }

    async check(force = false) {
        if (checkThrottled && !force) {
            return;
        }

        checkThrottled = true;
        setTimeout(() => {
            checkThrottled = false;
        }, intervalTime);

        const currVersion = this.model.get('_custom.project.version');
        console.log('check update', currVersion);

        const json: any = await (await fetch('/system/version')).json();
        if (currVersion && json.version && json.version !== currVersion) {
            setTimeout(() => {
                this.model.set('updateAvailable', true);
            }, 1000 * 60 * 2); // Задержка, чтобы успели обновиться все сервисы
        }
    }
}