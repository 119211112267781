import {App, util} from "derby";

/**
 * Привязка счетчика Яндекс.Метрики к Derby-приложению
 * @param ymId
 */
export function yandexMetrika(ymId: number) {
    return function (app: App) {
        if (!util.isServer) {
            app.on('load', () => {
                (function (m, e, t, r, i, k, a) {
                    m[i] = m[i] || function () {
                        (m[i].a = m[i].a || []).push(arguments)
                    };
                    m[i].l = Date.now();
                    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
                })
                (window, document, "script", "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js", "ym");

                // @ts-ignore
                ym(ymId, "init", {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                });
            });

            let lastReferer;

            app.on('destroyPage', () => {
                lastReferer = window.location.href;
            });

            app.on('routeDone', () => {
                if ('ym' in window) {
                    // @ts-ignore
                    ym(ymId, 'hit', window.location.href, {
                        title: document.title,
                        referer: lastReferer,
                    });
                }
            });
        }
    }
}