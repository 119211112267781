/**
 * Фикс переноса виджета между страницами для роутов Derby
 */
import derby, {App} from "derby";

export function derbyWidget(app: App) {
    if (!derby.util.isServer) {
        app.on('ready', () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = process.env.Q_URL + '/dist/widget.js';
            script.setAttribute('data-qc-api-key', '8ihdlgtf9tr1uxbit5dzqubhp0du51vl7');

            const model = app.model;
            const userId = model.get('_qchat.authId');
            const authKey = model.get('_qchat.authKey');
            if (userId && authKey) { // Авторизуем пользователя в чате
                script.setAttribute('data-qc-auth-id', userId);
                script.setAttribute('data-qc-auth-key', authKey);
            }

            script.onload = function () {
                window.qchatapi.onReady(() => {
                    const elems = [
                        document.getElementById('qchat_styles'), // Стили
                        document.getElementById('qchat_chat_frame'), // Фрейм
                        document.getElementById('qchat_button'), // Виджет
                    ];

                    // Удаляем элементы перед роутом
                    app.on('render', () => {
                        try {
                            for (let el of elems) {
                                el.remove();
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    });

                    // Добалвяем после
                    app.on('routeDone', () => {
                        try {
                            for (let el of elems) {
                                document.body.appendChild(el);
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    });
                });

                if (userId) {
                    // Устанавливаем данные пользователя
                    const $user = model.at('user').at(userId);
                    $user.fetch(err => {
                        if (err) throw err;

                        window.qchatapi.setProps({
                            name: $user.get('name'),
                            email: $user.get('email'),
                        });
                    });
                }
            };

            document.head.appendChild(script);
        });
    }
}