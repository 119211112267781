import derby from 'derby';
import {LoginForm} from "./components/login-form";
import {RegisterForm} from "./components/register-form";
import {appExtend} from "../../shared/plugin/app-extend";
import {modelExtend} from "../../shared/plugin/model-extend";
import {derbyWidget} from "../../widget/derby-widget";
import {yandexMetrika} from "./yandex-metrika";
import {Notifications} from "../components/notifications";
import {Modal} from "../components/modal";
import {ConnectionIndicator} from "../components/connection-indicator";
import {DerbyUpdateCheckComponent} from "../components/derby-update-check";

require('../../hacks-derby-async');

let app = derby.createApp('front', __filename);

if (derby.util.isServer) {
    app.serverUse(module, 'derby-less');
    app.serverUse(module, '../../server/utils/templates-html-fix');
}

app.use(modelExtend);
app.use(appExtend);
app.use(yandexMetrika(61763869));

app.component(Modal);
app.component(Notifications);
app.component(LoginForm);
app.component(RegisterForm);
app.component(ConnectionIndicator);
app.component(DerbyUpdateCheckComponent);

app.loadViews(__dirname + '/views');
app.loadStyles(__dirname + '/styles');

/**
 * Общий роут
 */
app.get('*', (page, model, params, next) => {
    // Подписываемся на сессионного пользователя
    const userId = model.get('_session.user.id');
    if (userId) {
        model.subscribe('user.' + userId, err => {
            if (err) return next(err);

            model.ref('_local.user', 'user.' + userId);
            return next();
        });
    } else {
        return next();
    }
});

app.get('/', function (page, model, params, next) {
    page.render('index');
});

app.get('/login', function (page, model, params, next) {
    page.render('login');
});

app.get('/register', function (page, model, params, next) {
    page.render('register');
});

app.get('/privacy-policy', function (page, model, params, next) {
    page.render('privacy-policy');
});

app.get('/agreement', function (page, model, params, next) {
    page.render('agreement');
});

app.get('/features', function (page, model, params, next) {
    page.render('features');
});

app.get('/docs', function (page, model, params, next) {
    page.render('docs');
});

app.get('/docs/auth', function (page, model, params, next) {
    page.render('docs-auth');
});

app.get('/docs/api', function (page, model, params, next) {
    page.render('docs-api');
});

app.get('/docs/api-server', function (page, model, params, next) {
    page.render('docs-api-server');
});

app.get('/tariffs', function (page, model, params, next) {
    page.render('tariffs');
});

app.get('/contacts', function (page, model, params, next) {
    page.render('contacts');
});

app.on('load', () => {
    app.model.set('_local.isClient', true);

    // Отключаем дефолтное поведение при дропе файлов
    window.addEventListener("dragover", function (e) {
        e.preventDefault();
    }, false);

    window.addEventListener("drop", function (e) {
        e.preventDefault();
    }, false);

    // Перематываем страницу вверх при смене роута
    app.on('routeDone', () => {
        window.scrollTo({top: 0, left: 0});
    });
});

derbyWidget(app); // Добавляем виджет чата в приложение

export default app;