import {activeBillings, classNames, date_pretty} from "../utils";
import {App, Model, Page} from "derby";

declare global {
    interface Window {
        PAGE?: Page;
        MODEL?: Model;
        APP?: App;
    }
}

/**
 * Расширяем приложение тем, что нам требуется
 * @param app
 */
export function appExtend(app: App) {
    app.proto.classNames = classNames;
    app.proto.date_year = function (date: Date | string | number, tzOffset: number): number {
        date = new Date(date);
        date.setFullYear(date.getUTCFullYear());
        date.setTime(date.getTime() - tzOffset);
        return date.getFullYear();
    };
    app.proto.date_pretty = date_pretty;
    app.proto.activeBillings = activeBillings;

    app.on('ready', function (page) {
        window.PAGE = page;
        window.MODEL = page.model;
        window.APP = app;
    });

    // Надстройки для показа даты/времени
    app.on('model', (model: Model) => {
        if (app.derby.util.isServer) {
            model.setEach('_time', {
                now: Date.now(),
                tz: (new Date()).getTimezoneOffset(),
            });
        }
    });

    app.on('load', () => {
        app.model.setEach('_time', {
            now: Date.now(),
            tz: (new Date()).getTimezoneOffset(),
        });

        setInterval(() => {
            app.model.set('_time.now', Date.now());
        }, 1000);
    });
}