import {Component} from "derby";
import {timeout} from "../../../../shared/utils";

export class RegisterForm extends Component {
    static is = 'register-form';
    static view = __dirname;
    static style = __dirname;

    init() {
        this.resetForm();
    }

    resetForm() {
        this.model.set('name', '');
        this.model.set('email', '');
        this.model.set('password', '');
        this.model.set('process', false);
    }

    async onSubmit(e) {
        e.preventDefault();

        try {
            await this.model.setAsync('process', true);

            const res: any = await (await fetch('/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: this.model.get('name'),
                    email: this.model.get('email'),
                    password: this.model.get('password'),
                    auto_login: true,
                }),
            })).json();

            if (!res.success) {
                throw new Error(res.error || 'Ошибка при выполнении запроса');
            }

            await window.Notifications.success('Вы успешно зарегистрированы');
            await timeout(2000);
            this.parent.app.history.push('/app');
        } catch (e) {
            console.error(e);
            window.Notifications.error(e.message || 'Не удается отправить запрос на регистрацию');
        } finally {
            this.model.set('process', false);
        }
    }
}