import {Component} from "derby";
import {timeout} from "../../../../shared/utils";

export class LoginForm extends Component {
    static is = 'login-form';
    static view = __dirname;
    static style = __dirname;

    init() {
        this.resetForm();
    }

    resetForm() {
        this.model.set('email', '');
        this.model.set('password', '');
        this.model.set('process', false);
    }

    async onSubmit(e) {
        e.preventDefault();

        try {
            await this.model.setAsync('process', true);

            const res: any = await (await fetch('/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.model.get('email'),
                    password: this.model.get('password'),
                }),
            })).json();

            if (!res.success) {
                throw new Error(res.error || 'Произошла ошибка при выполнении запроса');
            }

            await window.Notifications.success('Вы успешно авторизованы');
            await timeout(2000);
            this.parent.app.history.push('/app');
        } catch (e) {
            console.error(e);
            window.Notifications.error(e.message || 'Ошибка отправки запроса');
        } finally {
            this.model.set('process', false);
        }
    }
}